<template>
  <b-container fluid class="category-detail-container py-5">
    <b-row>
      <!-- Kolom Kiri: Informasi Kategori -->
      <b-col cols="12" md="6" class="d-flex flex-column justify-content-center">
        <b-card class="shadow-lg">
          <b-card-header class="bg-primary text-white text-center">
            <h3>{{ category.name }}</h3>
          </b-card-header>
          <b-card-body>
            <p class="text-secondary">{{ category.description }}</p>
            <h5 class="text-success font-weight-bold">
              Harga: Rp {{ category.price }}
            </h5>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Kolom Kanan: Pembayaran dan Status -->
      <b-col cols="12" md="6">
        <b-card class="shadow-lg">
          <b-card-header class="bg-secondary text-white text-center">
            <h4>Pembelian Paket</h4>
          </b-card-header>
          <b-card-body>
            <!-- Form Pembelian -->
            <b-form @submit.prevent="buyPackage">
              <b-form-group label="Masukkan Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  placeholder="Masukkan email Anda"
                ></b-form-input>
              </b-form-group>
              <!-- Perbaikan: Hanya satu tombol submit -->
              <b-button 
                variant="success" 
                block 
                type="submit" 
                :disabled="isLoading"
              >
                <b-spinner small v-if="isLoading"></b-spinner>
                {{ isLoading ? 'Memproses...' : 'Beli Paket' }}
              </b-button>
            </b-form>
            <hr />
            
            <!-- Status Pembayaran -->
            <div v-if="status === 'paid'" class="text-center mt-4">
              <p class="text-success font-weight-bold">
                Pembayaran berhasil! Anda dapat memulai tryout.
              </p>
              <b-button variant="primary" block @click="startTryout">
                Mulai Tryout
              </b-button>
            </div>
            <div v-else-if="status === 'expired'" class="text-center mt-4">
              <p class="text-danger">
                Pembayaran Anda telah kedaluwarsa. Silakan coba lagi.
              </p>
              <b-button variant="warning" block @click="retryPayment">
                Coba Bayar Lagi
              </b-button>
            </div>
            <div v-else class="text-center mt-4">
              <p class="text-warning">
                Anda belum membayar paket ini. Silakan selesaikan pembayaran.
              </p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      isLoading: false,
      email: localStorage.getItem("userEmail") || "",
      status: "unpaid",
      category: {},
      paymentInterval: null
    };
  },
  methods: {
    async buyPackage() {
      const categoryId = this.$route.params.id;

      if (!this.email) {
        alert("Silakan masukkan email untuk melanjutkan.");
        return;
      }

      this.isLoading = true;

      try {
        const isGuest = await this.checkGuestExists();
        if (!isGuest) {
          await this.guestRegister();
        }

        const checkResponse = await api.get(`/categories/${categoryId}/check-payment`, {
          params: { email: this.email },
        });

        if (checkResponse.data.canContinue) {
          await this.handleSnapPayment(checkResponse.data.payment.token);
        } else {
          const response = await api.post(`/categories/${categoryId}/buy/`, {
            email: this.email,
          });
          await this.handleSnapPayment(response.data.snap_token);
        }
      } catch (error) {
        console.error("Error during purchase:", error);
        alert(error.response?.data?.message || "Terjadi kesalahan saat memproses pembayaran");
      } finally {
        this.isLoading = false;
      }
    },

    async handleSnapPayment(token) {
      try {
        await new Promise((resolve, reject) => {
          window.snap.pay(token, {
            onSuccess: async () => {
              await this.checkPaymentStatus();
              alert("Pembayaran berhasil! Silakan akses tryout.");
              resolve();
            },
            onPending: async () => {
              await this.checkPaymentStatus();
              alert("Pembayaran tertunda. Kami akan memproses verifikasi Anda.");
              resolve();
            },
            onError: (error) => {
              alert(`Pembayaran gagal: ${error.message}`);
              reject(error);
            },
            onClose: () => {
              alert("Anda menutup popup sebelum menyelesaikan pembayaran");
              reject(new Error("Payment popup closed"));
            }
          });
        });
      } catch (error) {
        console.error("Payment error:", error);
      }
    },

    async checkPaymentStatus() {
      try {
        const response = await api.get(
          `/categories/${this.$route.params.id}/check-payment/`,
          { params: { email: this.email } }
        );
        
        this.status = response.data.status;
        if (this.status === 'paid') {
          localStorage.setItem('paidCategory', this.$route.params.id);
          clearInterval(this.paymentInterval);
        }
      } catch (error) {
        console.error("Payment status check failed:", error);
      }
    },

    async fetchCategoryDetails() {
      const categoryId = this.$route.params.id;
      try {
        const response = await api.get(`/categories/${categoryId}/`);
        this.category = response.data;
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    },

    async retryPayment() {
      this.status = 'unpaid';
      await this.buyPackage();
    },

    async checkGuestExists() {
      try {
        const response = await api.get("/auth/check-email/", {
          params: { email: this.email },
        });
        return response.data.exists;
      } catch (error) {
        console.error("Error checking email existence:", error);
        return false;
      }
    },

    async startTryout() {
      const categoryId = this.$route.params.id;
      try {
        await api.get(`/categories/${categoryId}/start-tryout/`, {
          params: { email: this.email },
        });
        this.$router.push(`/tryout/${categoryId}`);
      } catch (error) {
        alert("Silahkan login untuk memulai tryout ini (cek username dan password pada email).");
      }
    },

    async guestRegister() {
      if (!this.email) {
        alert("Silakan masukkan email untuk mendaftar.");
        return;
      }

      try {
        const response = await api.post("/auth/guest-register/", {
          email: this.email,
          name: "Guest",
        });
        alert(response.data.message);
      } catch (error) {
        const message = error.response?.data?.message;
        alert(message || "Email sudah terdaftar, silahkan login untuk cek paket tryout");
      }
    },
  },
  async mounted() {
    await this.fetchCategoryDetails();
    await this.checkPaymentStatus();
    
    if (this.status === 'unpaid') {
      this.paymentInterval = setInterval(this.checkPaymentStatus, 30000);
    }
  },
  beforeUnmount() {
    if (this.paymentInterval) {
      clearInterval(this.paymentInterval);
    }
  }
};
</script>

<style scoped>
.category-detail-container {
  background: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
  min-height: 100vh;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>